import React from 'react';
import { Link } from 'react-router-dom';
import BaseSelection from '../components/BaseSelection';
import Header from '../components/Header';
import { connect } from 'react-redux';
import { getEnvConfig } from '../env';
import axios from 'axios';

class Home extends React.Component {

    state={
        openFriendsSelection: false,
    }

    env = null;

    componentDidMount = () => {
        this.props.dispathUpdateInternalLocale();

        // tracking
        this.env = getEnvConfig();
        axios.post(this.env.apibase + 'tracking.php?cv='+Date.now(), { url: window.location.href }).then(res => {}).catch(e=>{})


        this.props.dispathResetOldComposition(); // is case of theme switch reset old values
    }

    scrollToThemes = () => {

        const themeCont = document.getElementsByClassName("home-themes");

        window.scrollTo({
            top: themeCont[0].offsetTop - 200,
            left: 0,
            behavior: 'smooth'
        });
    }

     dateToUnixTimestamp = (dateString) => {
        const date = new Date(dateString)
        // Eine Stunde in Millisekunden abziehen
        const offsetInMilliseconds = 60 * 60 * 1000
        const adjustedDate = new Date(date.getTime() - offsetInMilliseconds)
        return Math.floor(adjustedDate.getTime() / 1000)
    }
    
    render = () => {

        const isHolidaysActive = parseInt(Math.floor(Date.now() / 1000).toString()) > this.dateToUnixTimestamp("2024-12-01T00:00:00Z")
	

       
        return (
            <>
                <Header />

                <picture className="resp-img">
                    <source srcSet={ "./images/keyvisuals/" + this.props.locale + "-keyVisual_forWeb_Wide.png" } media="(min-width: 600px)" />
                    <img decoding="async" loading="lazy" src={ "./images/keyvisuals/" + this.props.locale + "-keyVisual_forWeb_Square.png" } alt="Visual" />
                </picture>
                
                <div className="home" >

                    <div className="limitcontent">
                        <h2 className="contentPadding u-textCenter u-paddingTopXl u-marginTopXl u-marginBottomXl">{ this.props.t.t("homeHeadlineSteps")}</h2>
                    </div>
                    <div className="home-gradient ">
                    </div>
                    <div className="limitcontent ">

                        <div className="Grid u-textCenter">
                            <div className="u-sm-size1of1 u-md-size1of3">
                                <img decoding="async" loading="lazy" className="u-marginBottomSm home-gradient" src="../images/step1.png" alt="Step 1"/>
                                <p className="contentPadding">{ this.props.t.t("homeStep1")}</p>
                            </div>
                            <div className="u-sm-size1of1 u-md-size1of3">
                                <img decoding="async" loading="lazy" className="u-marginBottomSm home-gradient" src="../images/step2.png" alt="Step 2"/>
                                <p className="contentPadding">{ this.props.t.t("homeStep2")}</p>
                            </div>
                            <div className="u-sm-size1of1 u-md-size1of3">
                                <img decoding="async" loading="lazy" className="u-marginBottomSm home-gradient" src="../images/step3.png" alt="Step 3"/>
                                <p className="contentPadding">{ this.props.t.t("homeStep3")}</p>
                            </div>
                        </div>

                        <h2 className="contentPadding u-textCenter u-paddingTopXl u-marginTopXl u-marginBottomXl">{ this.props.t.t("homeThemeHeadline")}</h2>

                        <div className="contentPadding Grid Grid--withGutter home-limitWidth home-themes u-paddingBottomXl ">
{isHolidaysActive && 
                            <Link to="create/holidays" className="u-size1of2 u-marginBottomMd">
                                <img decoding="async" loading="lazy" className="" src="../images/theme_holidays.jpg" alt="LEGO holidays"/>
                                <p>{ this.props.t.t("homeThemeHolidays")}</p>
                            </Link>
}
                            <Link to="create/minifigures" className="u-size1of2 u-marginBottomMd">
                                <img decoding="async" loading="lazy" className="" src="../images/theme_minifigures.jpg" alt="LEGO Minifigures"/>
                                <p>{ this.props.t.t("homeThemeMinifigures")}</p>
                            </Link>
                            <Link to="create/city" className="u-size1of2 u-marginBottomMd">
                                <img decoding="async" loading="lazy" className="" src="../images/theme_city.jpg" alt="LEGO City"/>
                                <p>{ this.props.t.t("homeThemeCity")}</p>
                            </Link>
                            <Link to="create/ninjago" className="u-size1of2 u-marginBottomMd">
                                <img decoding="async" loading="lazy" className="" src="../images/theme_ninjago.jpg" alt="LEGO Ninjago"/>
                                <p>{ this.props.t.t("homeThemeNinjago")}</p>
                            </Link>
                            <div onClick={ () => this.setState({ openFriendsSelection : true }) } className="u-size1of2 u-marginBottomMd">
                                <img decoding="async" loading="lazy" className="" src="../images/theme_friends.jpg" alt="LEGO Friends"/>
                                <p>{ this.props.t.t("homeThemeFriends")}</p>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="homeBanner" onClick={ this.scrollToThemes }>
                    <div className="Grid Grid--withGutter">
                        <div className="u-sizeFit">
                            <img decoding="async" loading="lazy" src="./images/start_designing.png" alt="" className="homeBanner-oversizeimg" />
                        </div>
                        <p className="u-sizeFit">
                            { this.props.t.t("homeThemeHeadline")}
                        </p>
                    </div>
                </div>


                { this.state.openFriendsSelection && 
                    <BaseSelection 
                        hideOverlay={ ()=> this.setState({ openFriendsSelection: false })}
                        t={ this.props.t }
                    />
                }
                {/* { window.location.origin === "http://localhost:3000" &&
                <>
                    <Link to={ "/"+this.props.locale +"/share/dfghjkl"}>Share</Link>
                    <Link to={ "/"+this.props.locale +"/thankyou/dfghjkl"}>Thankyou</Link>
                </>
                } */}

            </>
        )
    }

}
    
const mapStateToProp = state => ({
    t: state.t,
    locale: state.locale,
});

const mapDispatchToProps = dispatch => {
    return {
        dispathUpdateInternalLocale : () => dispatch({ type: "UPDATE_LOCALE" }),
        dispathResetOldComposition : () => dispatch({ type: "RESET_COMPOSITION" }),
    };
}


export default connect(mapStateToProp, mapDispatchToProps)(Home);    
    